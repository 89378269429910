@import '../../css/variables.scss';

.searchBarWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  border-bottom: 1px solid $borderColor;
  box-shadow: 0 8px 8px rgb(0 0 0 / 40%);
  box-sizing: border-box;
  // min-height: 150px;

  .searchBar {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 2;

    button {
      margin: 10px;
      padding: 4px 6px;
      border-radius: 4px;
      border: none;
    }

    .searchFilters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .searchTextWrapper {
      input {
        border: none;
        border-radius: 4px;
        min-width: 200px;
        padding: 6px 20px;
        margin: 10px;

        &:focus {
          outline-style: none;
        }
      }
    }
    .colourFilters {
      button {
        border: none;
        cursor: pointer;
        margin: 4px;
        border-radius: 10px;
        width: 20px;
        height: 20px;
        transition: 0.3s;
        opacity: 0.7;
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.4);

        &:hover {
          opacity: 1;
          transform: scale(1.2);
        }
      }

      .filterRedEnabled {
        background-color: #e5002d;
        transform: scale(1.2);
      }
      .filterRedDisabled {
        background-color: #e5002d;
        transform: none;
      }
      .filterBlueEnabled {
        background-color: #0096e0;
        transform: scale(1.2);
      }
      .filterBlueDisabled {
        background-color: #0096e0;
        transform: none;
      }
      .filterYellowEnabled {
        background-color: #fee000;
        transform: scale(1.2);
      }
      .filterYellowDisabled {
        background-color: #fee000;
        transform: none;
      }
      .filterGreenEnabled {
        background-color: #009a6a;
        transform: scale(1.2);
      }
      .filterGreenDisabled {
        background-color: #009a6a;
        transform: none;
      }
      .filterBlackEnabled {
        background-color: #221714;
        transform: scale(1.2);
      }
      .filterBlackDisabled {
        background-color: #221714;
        transform: none;
      }
      .filterPurpleEnabled {
        background-color: #6255a2;
        transform: scale(1.2);
      }
      .filterPurpleDisabled {
        background-color: #6255a2;
        transform: none;
      }
      .filterWhiteEnabled {
        background-color: #ffffff;
        transform: scale(1.2);
      }
      .filterWhiteDisabled {
        background-color: #ffffff;
        transform: none;
      }
    }
  }
  .searchBarStatsOpen {
    height: 100%;
    width: 300px;
    border: 1px solid white;
    box-sizing: border-box;
    margin: 0 0 0 auto;
    transition: width 0.5s;
  }
  .searchBarStatsClosed {
    width: 0px;
    border: 1px solid white;
    box-sizing: border-box;
    margin: 0 0 0 auto;
    transition: width 0.5s;
  }
}

.searchName {
  height: 38px;
  min-width: 100px;
  padding: 0px 12px;
  border-radius: 4px;
  border: none;
  outline: none;
  margin: 0px 5px;
}

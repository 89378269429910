@import "../../css/variables.scss";

.buildWrapper {
  height: 100%;
  display: flex;
}

.searchWrapperBuild {
  @include maxWidthHeight;
  position: relative;
  overflow: hidden;
}

.cardGrid {
  @include scrollBar(transparent, rgba(250, 250, 250, 0.5), 8px);

  display: flex;
  justify-content: center;
  overflow-y: auto !important;
  overflow-x: hidden !important;

  padding-top: var(--offset);

  > :first-child {
    display: flex;
    justify-content: center;
    position: relative;
  }

  &::-webkit-scrollbar-track {
    margin-top: var(--offset);
  }
}

.cardModalWrapper {
  .cardModal {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(0.2rem);
    border-radius: 5px;
    width: 400px;
    height: 400px;
    z-index: 5;
    position: fixed;
    // 50% - half of deck sidebar - half of scrollbar width
    left: calc(50% - 200px - 4px);
    // 50% + half of navbar height
    top: calc(50% + 30px);
    transform: translate(-50%, -50%);
  }
}

.deckWrapperBuild {
  @include maxWidthHeight;
  @include scrollBar(transparent, rgba(250, 250, 250, 0.5), 8px);
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.deckViewAppBar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 6;
  backdrop-filter: blur(2px);
  background-color: $backgroundColor;
  border-bottom: 1px solid #11366a;
  border-right: 1px solid #11366a;
  box-sizing: border-box;
}

.deckViewName {
  @include flexRowCenter;
  font-size: 18px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  span {
    padding: 0 10px;
  }
  input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    background-color: transparent;
    color: rgba(250, 250, 250, 1);
    border: none;
    text-align: center;
    font-weight: bold;
    outline: none;
  }
  svg {
    width: 18px;
  }
}

.deckCards {
  margin: 20px 0;
  display: grid;
  justify-content: center;
  grid: auto-flow 250px / repeat(auto-fill, 180px);
  gap: 80px 25px;
  transition: 200ms;

  @include media(">phone", "<=desktop") {
    grid: auto-flow 210px / repeat(auto-fill, 140px);
    gap: 50px 25px;
  }
  @include media("<=phone") {
    grid: auto-flow 210px / repeat(auto-fill, 140px);
    gap: 50px 25px;
  }
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

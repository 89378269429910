.fade-card-enter {
  opacity: 0 !important;
  height: 0 !important;
  min-height: 0 !important;
  .deckSideBarCardCount,
  .deckSideBarCardName,
  .deckSideBarCardNumber {
    opacity: 0 !important;
  }
}
.fade-card-enter-active {
  opacity: 1 !important;
  height: 40px !important;
  transition: height 400ms, opacity 400ms !important;
  .deckSideBarCardCount,
  .deckSideBarCardName,
  .deckSideBarCardNumber {
    opacity: 1 !important;
    transition: opacity 600ms !important;
  }
}
.fade-card-exit {
  opacity: 1 !important;
  .deckSideBarCardCount,
  .deckSideBarCardName,
  .deckSideBarCardNumber {
    opacity: 1 !important;
  }
}
.fade-card-exit-active {
  opacity: 0 !important;
  height: 0 !important;
  min-height: 0 !important;

  .deckSideBarCardCount,
  .deckSideBarCardName,
  .deckSideBarCardNumber {
    opacity: 0 !important;
    transition: opacity 400ms !important;
  }

  transition: min-height 400ms, height 400ms, opacity 400ms !important;
}

.sidebar-enter {
  transform: translateX(320px);
}
.sidebar-enter-active {
  transform: translateX(0);
  transition: width 500ms, opacity 500ms, transform 500ms;
}
.sidebar-exit {
  transform: translateX(0);
}
.sidebar-exit-active {
  transform: translateX(320px);
  transition: width 500ms, opacity 500ms, transform 500ms;
}
.sidebar-exited {
  transform: translateX(320px);
}

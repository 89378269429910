@import "./_include-media";
@import "./variables";

html,
body {
  @include maxWidthHeight;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #000000;
  padding: 0;
  margin: 0;

  background: $backgroundGradient;

  #root {
    @include maxWidthHeight;
    background-size: 32px 32px;
    background-image: linear-gradient(
        to right,
        $borderColor 1px,
        transparent 1px
      ),
      linear-gradient(to bottom, $borderColor 1px, transparent 1px);
    animation: 4s linear infinite scrollBackgroundPosY;

    .app {
      width: 100%;
      height: 100%;

      .navBar {
        font-weight: bold;
        top: 0;
        width: 100%;
        height: 60px;
        position: fixed;
        display: flex;
        @include backdropBlur;
        // background: linear-gradient(90deg, #000000, #001a40);
        background: $backgroundColor;
        box-sizing: border-box;
        border-bottom: 1px solid $borderColor;

        a {
          transition: 0.2s;
          text-decoration: none;
          color: rgba(250, 250, 250, 0.8);

          &:hover {
            cursor: pointer;
            color: rgba(250, 250, 250, 1);
          }
        }

        .navLogo {
          display: flex;
          align-items: center;
          height: 100%;
          margin: 0 auto 0 0;

          span {
            padding: 0 10px 0 10px;

            a {
              text-decoration: none;
              color: rgba(250, 250, 250, 1);
            }
          }
        }

        .navLinks {
          display: flex;
          align-items: center;
          height: 100%;
          margin: 0 auto 0 10px;

          span {
            padding: 0 15px 0 15px;
          }
        }

        .navAccount {
          @include flexRowEnd;
          height: 100%;

          span {
            padding: 0 10px 0 10px;
          }
        }
      }

      .appBar {
        display: flex;
        align-items: center;
        height: 60px;
        background-color: #dcdcdc;

        ul {
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          list-style: none;
          padding: 0;
          margin: 0 40px;

          li {
            @include flexRowCenter;
            min-width: 80px;
            height: 20px;
            border-radius: 40px;
            padding: 5px 10px;
            margin: 10px;
            background-color: #f8f8f8;
            transition: 0.2s;

            &:hover {
              cursor: pointer;
              color: #ffffff;
              background-color: #000000;
            }
          }
        }
      }

      .searchWrapper {
        overflow-y: scroll;
        @include scrollBar($mediumAppColor, $darkAppColor, 12px);
      }

      .uiCardWrapper {
        @include flexRowCenter;
        @include maxWidthHeight;

        @include media(">phone", "<=tablet") {
          @include flexColumnCenter;
        }

        a {
          text-decoration: none;
          color: black;
          @include maxWidthHeight;
          @include flexRowCenter;

          &:hover {
            color: #8f8f8f;
          }
        }

        .uiCard {
          @include flexRowCenter;
          @include blackInsideBorder;
          text-decoration: none;
          color: black;
          font-weight: bold;
          font-size: 30px;
          border-radius: 10px;
          min-width: 350px;
          width: 25%;
          min-height: 150px;
          height: 250px;
          margin: 10px;

          @include media(">phone", "<=tablet") {
            width: 50%;
          }
        }
      }

      .addCard {
        @include flexRowCenter;

        span {
          font-size: 5rem;
        }

        &:hover {
          span {
            color: #8f8f8f;
          }
        }
      }

      .formWrapper {
        @include flexColumnCenter;
        margin: 120px 0px;

        form {
          @include flexColumnCenter;

          button {
            width: calc(100% - 20px);
            padding: 10px;
            margin: 10px;

            &:hover {
              cursor: pointer;
            }
          }

          input {
            min-width: 180px;
            padding: 10px;
            margin: 10px;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: 0.2s ease-in-out 0s 1 fadeIn;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation: 0.2s ease-in-out 0s 1 fadeOut;
}

@keyframes scrollBackgroundPosY {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: 32px 32px;
  }
}

.fade-enter {
  opacity: 0 !important;
}

.fade-enter-active {
  opacity: 1 !important;
  transition: opacity 400ms !important;
}

.fade-exit {
  opacity: 1 !important;
}

.fade-exit-active {
  opacity: 0 !important;
  transition: opacity 400ms !important;
}

@import "./components/saveModal.scss";
@import "./views/decks.scss";

@import "./_include-media";

$breakpoints: (
  phone: 576px,
  tablet: 768px,
  laptop: 992px,
  desktop: 1200px,
);

// app colours
$darkAppColor: rgba(0, 0, 0, 1);
$mediumAppColor: rgba(45, 56, 104, 1);

$backgroundColor: rgba(0, 0, 0, 0.4);
$backgroundGradient: linear-gradient(45deg, #000000, rgb(0, 26, 64));

$borderColor: rgba(17, 54, 106, 0.4);

@mixin backdropBlur {
  backdrop-filter: blur(2px);
}

@mixin maxWidthHeight {
  width: 100%;
  height: 100%;
}

@mixin flexRowCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexRowStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flexRowEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin blackInsideBorder {
  box-sizing: border-box;
  border: 1px solid black;
}

@mixin scrollBar($trackColor, $barColor, $width) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background: $trackColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $barColor;
    // border-radius: 20px;
    border: 2px solid $trackColor;
  }
}

@mixin gridBackground {
  background-size: 32px 32px;
  background-image: linear-gradient(to right, $borderColor 1px, transparent 1px),
    linear-gradient(to bottom, $borderColor 1px, transparent 1px);
  animation: 4s linear infinite scrollBackgroundPosY;
}

@keyframes scrollBackgroundPosY {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: 32px 32px;
  }
}

@keyframes scrollPosX {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

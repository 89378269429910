@import "../../css/variables.scss";

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 300px;
  width: 20%;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  overflow: hidden;
  z-index: 100;
  img {
    border-radius: 8px;
    width: 100%;
  }
}

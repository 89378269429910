@import '../../css/variables.scss';

.deckSideBar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 320px;
  border-left: 1px solid $borderColor;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  color: rgba(250, 250, 250, 1);
  @include scrollBar(transparent, rgba(250, 250, 250, 0.9), 4px);
  box-sizing: border-box;
  transition: min-width 500ms;
}

.deckSideBarCollapsed {
  height: 100%;
  min-width: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid $borderColor;
  box-sizing: border-box;
  color: rgba(250, 250, 250, 1);
  background-color: rgba(0, 0, 0, 0.4);
  transition: min-width 500ms;
  overflow: hidden;
  z-index: 6;
}

.deckSideBarTop {
  position: sticky;
  z-index: 1;
  top: 0;
  right: 0;
  min-height: 150px;
  border-bottom: 1px solid $borderColor;
  box-shadow: 0 8px 8px rgb(0 0 0 / 40%);
}

.deckSideBarName {
  @include flexRowCenter;
  padding: 4px;
  min-height: 40px;
  opacity: 1;
  // border-bottom: 1px solid $borderColor;
}

.deckSideBarFunctions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 4px;
  height: 40px;

  svg {
    width: 24px;
    cursor: pointer;
    transition: 0.2s;
    padding: 2px;
    filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 40%));

    &:hover {
      transform: scale(1.3);
    }
  }
}

.deckSideBarFunctionsCollapsed {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  div {
    margin: 10px 0px;
  }

  svg {
    width: 24px;
    cursor: pointer;
    transition: 0.2s;
    padding: 2px;

    &:hover {
      transform: scale(1.3);
    }
  }
}

.deckSideBarLevels {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
  box-sizing: border-box;
  text-shadow: 0 2px 2px rgb(0 0 0 / 40%);

  div {
    height: 100%;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .levelHeader {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.deckSideBarCards {
  flex: 1;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      min-height: 30px;
    }
  }
}

.deckSideBarCard {
  @include flexRowStart;
  position: relative;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  font-weight: bold;
  color: rgba(250, 250, 250, 1);
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  opacity: 1;
  transition: height 200ms;

  &:hover {
    height: 64px;

    .deckSideBarCardOptions {
      opacity: 1;
      transform: translateX(-150px);
    }

    .deckSideBarCardName {
      text-overflow: unset;
      white-space: normal;
      overflow: visible;
    }
  }
}

.deckSideBarCardCount {
  width: 10%;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  padding: 2px 0px;
  border-radius: 4px;
  text-shadow: 0 2px 2px rgb(0 0 0 / 40%);
}

.deckSideBarCardNumber {
  width: 20%;
  margin: 0 10px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  border-radius: 4px;
  text-shadow: 0 2px 2px rgb(0 0 0 / 40%);
}

.deckSideBarCardName {
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px 4px;
  border-radius: 4px;

  span {
    text-shadow: 0 2px 2px rgb(0 0 0 / 40%);
  }
}

// TODO: Clean up
.options {
  position: absolute;
  display: flex;
  top: 0%;
  bottom: 0%;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.2s;
  background-color: rgba(0, 0, 0, 0.4);

  &:hover {
    opacity: 1;
  }

  button {
    cursor: pointer;
    height: 100%;
    flex: 1;
    border-width: 0;
    background: none;
    color: rgba(250, 250, 250, 0.9);
    font-size: 1.5em;
    transition: 0.2s;

    svg {
      width: 20px;
      transition: 0.2s;
      filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 40%));
    }

    &:hover {
      svg {
        transform: scale(1.3);
      }
    }
  }
}

.deckSideBarCardAmount {
  @include flexRowCenter;
  width: 100%;
  font-weight: bold;
  height: 40px;

  span {
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    background-image: linear-gradient(90deg, transparent, #001a40, transparent);
    background-repeat: repeat-x;
    animation: 3s linear infinite scrollPosX;
    z-index: 1;
  }

  border-bottom: 1px solid $borderColor;
}

.sideBarTooltipWrapper {
  position: relative;
  user-select: none;

  .sideBarTooltipBottom {
    left: 50%;
    transform: translate(-50%, 0);
  }

  .sideBarTooltipLeft {
    left: -100%;
    transform: translate(-100%, -100%);
  }

  .sideBarTooltip {
    @include gridBackground;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    min-width: 100px;
    padding: 0px 5px;
    visibility: hidden;
    border: 1px solid $borderColor;
    background-color: rgba(0, 0, 0, 0.9);
    filter: blur(4px);
    transition: visibility 200ms, height 200ms, filter 300ms;

    span {
      display: none;
    }
  }

  &:hover {
    .sideBarTooltip {
      height: 50px;
      min-width: 100px;
      visibility: visible;
      filter: blur(0px);

      span {
        display: inline-block;
      }
    }
  }
}

.sidebarFunctionClicked {
  transform: translate(0px, 5px);
}

@keyframes clicked {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.decksWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 60px;
}
.decks {
  width: 70%;
  height: 100%;
  display: grid;
  justify-content: center;
  grid: auto-flow minmax(210px, 250px) / repeat(auto-fill, minmax(200px, 240px));
  gap: 30px;
  overflow-x: hidden !important;
  overflow-y: auto;
  padding: 30px 0;
  box-sizing: border-box;

  @include media(">phone", "<=tablet") {
    @include maxWidthHeight;
  }
  @include media(">tablet", "<=desktop") {
    @include maxWidthHeight;
  }
  @include media("<=phone") {
    @include maxWidthHeight;
    .deck {
      width: 200px;
      height: 210px;
    }
    .newDeck {
      width: 200px;
      height: 210px;
    }
  }
}
.deck {
  min-width: 200px;
  min-height: 210px;
  width: 240px;
  height: 250px;
  border-radius: 5px;
  transition: 0.2s;
  background-color: $backgroundColor;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}
.deckImage {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  transition: 0.2s;

  img {
    width: 100%;
  }
  height: 80%;
  width: 100%;
}
.deckName {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgba(250, 250, 250, 0.8);
  border-radius: 0 0 5px 5px;
  height: 20%;
  width: 100%;
  transition: 0.2s;
}
.newDeck {
  min-width: 200px;
  min-height: 210px;
  width: 240px;
  height: 250px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8rem;
  background-color: $backgroundColor;
  color: rgba(250, 250, 250, 0.783);
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

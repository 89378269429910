@mixin multiCardTransform($opacity, $two, $three, $four) {
  &:nth-child(2) {
    transform: translateY($two);
    opacity: $opacity;
  }

  &:nth-child(3) {
    transform: translateY($three);
  }

  &:nth-child(4) {
    transform: translateY($four);
  }
}

.multiCard-enter {
  @include multiCardTransform(0, 0px, 15px, 30px);
}

.multiCard-enter-active {
  @include multiCardTransform(1, 15px, 30px, 45px);
  transition: opacity 200ms, transform 200ms;
}

.multiCard-enter-done {
  @include multiCardTransform(1, 15px, 30px, 45px);
}

.multiCard-exit {
  @include multiCardTransform(1, 15px, 30px, 45px);
}

.multiCard-exit-active {
  @include multiCardTransform(0, 0px, 15px, 30px);
  transition: opacity 200ms, transform 200ms;
}

.multiCard-exit-done {
  @include multiCardTransform(0, 0px, 15px, 30px);
}

.multiCardModal-enter {
  opacity: 0;
  transform: scale(0.8) translate(-50%, -50%);
  transform-origin: top left;
}

.multiCardModal-enter-active {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: top left;
  transition: opacity 210ms, transform 200ms;
}

.multiCardModal-enter-done {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: top left;
}

.multiCardModal-exit {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: top left;
}

.multiCardModal-exit-active {
  opacity: 0;
  transform: scale(0.8) translate(-50%, -50%);
  transform-origin: top left;
  transition: opacity 210ms, transform 200ms;
}

.multiCardModal-exit-done {
  opacity: 0;
  transform: scale(0.8) translate(-50%, -50%);
  transform-origin: top left;
}

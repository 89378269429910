.saveDeck {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: rgba(250, 250, 250, 1);
  // h3 {
  // border: 1px solid white;
  // }

  .saveDeckOptions {
    display: flex;
    align-items: flex-start;
    // border: 1px solid white;
    // img {
    //     margin: 10px;
    //     width: 200px;
    // }

    .saveDeckForm {
      display: flex;
      flex-direction: column;
      input {
        margin: 10px;
        border-radius: 4px;
      }
    }
  }
}

@import "../../css/variables.scss";

.deckName {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  // TODO: use variable
  text-shadow: 0 2px 2px rgb(0 0 0 / 40%);
  background-color: transparent;
  color: rgba(250, 250, 250, 1);
  margin: 4px;
  padding: 2px 0px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  outline: none;
  border: none;
  box-sizing: border-box;
  transition: background-color 200ms;
}

.deckName:focus {
  background-color: $backgroundColor;
  border: 1px solid $borderColor;
}

.deckName::placeholder {
  color: rgba(250, 250, 250, 0.6);
}

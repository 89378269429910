@import '../../css/variables.scss';

.card {
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  cursor: pointer;

  .back {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  .front {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;

    // Define base z-index to transition back to correctly
    z-index: 0;
    transition: all 200ms;

    img {
      width: 100%;
      height: 100%;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
    }
  }

  .front:not(.modal):hover {
    z-index: 100;
    transform: scale(1.1);

    .cardButton {
      visibility: visible;
      opacity: 1;
    }

    img {
      box-shadow: 0px 9px 8px rgba(0, 0, 0, 0.5);
    }
  }

  .front.modal {
    transform: scale(2.2);
    z-index: 1000;

    .options {
      background: none;
    }

    img {
      box-shadow: 0px 18px 16px rgba(0, 0, 0, 0.5);
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1);
      image-rendering: optimizeQuality;
    }
  }

  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.multiCard {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);

  img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  }

  &:nth-child(1) {
    z-index: 5;
    transition: transform 200ms;

    &:not(.modal):hover {
      transform: translateY(-15px) !important;
    }
  }

  &:nth-child(1).modal {
    transform: scale(2.2);
    z-index: 1000;

    img {
      box-shadow: 0px 18px 16px rgba(0, 0, 0, 0.5);
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1);
      image-rendering: optimizeQuality;
    }
  }

  &:nth-child(2) {
    z-index: 4;
  }

  &:nth-child(3) {
    z-index: 3;
  }

  &:nth-child(4) {
    z-index: 2;
  }
}

.multiCardModal {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 300px;
  width: 20%;
  border-radius: 5px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 100;
  img {
    border-radius: 8px;
    width: 100%;
  }
}

// TODO: Clean up
.options {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 0%;
  bottom: 0%;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.2s;
  background-color: rgba(0, 0, 0, 0.4);

  &:hover {
    opacity: 1;
  }

  button {
    cursor: pointer;
    height: 100%;
    flex: 1;
    border-width: 0;
    background: none;
    color: rgba(250, 250, 250, 0.9);
    font-size: 1.5em;
    transition: 0.2s;

    svg {
      width: 20px;
      transition: 0.2s;
      filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 80%));
    }

    &:hover {
      svg {
        transform: scale(1.3);
      }
    }
  }
}

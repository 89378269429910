.deckView-appear-done {
  opacity: 1;
  transition: opacity 400ms;
}
.deckView-enter {
  opacity: 0;
}
.deckView-exit {
  opacity: 1;
}
.deckView-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.deckView-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}
